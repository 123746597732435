import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "p-d-flex p-m-3 p-mt-4 p-mb-3 p-pl-2 p-text-left",
  style: {"border-left":"solid 5px black"}
}
const _hoisted_2 = { class: "p-text-bold" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "f-size-14 text-gray-1" }
const _hoisted_5 = { class: "w-100 p-d-flex p-jc-end p-my-auto" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "p-d-flex" }
const _hoisted_8 = { class: "p-ml-2 p-my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(['w-100', { 'p-m-auto': !_ctx.isDisplayInvoice }])
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.tittle), 1)
        ]),
        (_ctx.isDisplayInvoice)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.transactionInvoice), 1)
            ]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Dropdown, {
          options: _ctx.statusListDisplay,
          optionLabel: "name",
          placeholder: "",
          class: "dropdown-status",
          style: _normalizeStyle({
            'background-color': _ctx.brandConfig.colorThemeMain,
            'max-height': 'unset',
          })
        }, {
          value: _withCtx(() => [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.statusName), 1)
          ]),
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                style: _normalizeStyle(_ctx.getStatusStyle(slotProps.option.id))
              }, _toDisplayString(slotProps.index + 1), 5),
              _createElementVNode("div", _hoisted_8, _toDisplayString(slotProps.option.name), 1)
            ])
          ]),
          _: 1
        }, 8, ["options", "style"])
      ])
    ])
  ]))
}